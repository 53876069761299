<template>
  <div class="layout-container nicotine-warning-block">
    <div
      class="nicotine-warning py-24 px-16 text-center flex justify-center items-center border-grey800 border-4 type-headline-md bg-white text-black"
    >
      {{ block.Warning }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { type WarningBlockFragment } from '#gql';
const props = defineProps<{
  block: WarningBlockFragment,
}>();

const data = computed(() => {
  return props.block as WarningBlockFragment;
});
</script>

<style scoped lang="postcss">
.nicotine-warning {
  font-family: Arial, Helvetica, Sans-Serif !important;
}
</style>

<style>
.takeover-block + .nicotine-warning-block {
  @apply pt-40;
}
@screen md {
  .takeover-block + .nicotine-warning-block {
    @apply pt-56;
  }
}

.nicotine-warning-block {
  &:last-child {
    @apply mb-56 md:mb-40;
  }
}
</style>
